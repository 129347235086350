import { useState, useEffect } from 'react';
import { debounce } from '@/lib/helpers/debounce';

const DELAY = 100; // 0.1 second

export function useWindowSize() {
  const [size, setSize] = useState<[number, number]>([0, 0]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setSize([window.innerWidth, window.innerHeight]);
      };

      handleResize();

      const debouncedUpdateWindowSize = debounce(handleResize, DELAY);

      window.addEventListener('resize', debouncedUpdateWindowSize);

      return () => {
        window.removeEventListener('resize', debouncedUpdateWindowSize);
      };
    }

    return undefined;
  }, []);

  return size;
}
