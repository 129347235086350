import { MINIMAL_DESKTOP_WIDTH } from '@/constants/breakpoints';
import { useWindowSize } from '@/hooks/useWindowSize';

export const useIsMobileWidth = () => {
  const [deviceWidth] = useWindowSize();

  const isMobileWidth = deviceWidth === 0
    ? false
    : deviceWidth < MINIMAL_DESKTOP_WIDTH;

  return isMobileWidth;
};
