import { useEffect } from 'react';
import i18Next from '@/middleware/i18n';

interface UseRouteChangeCallback {
  (callback: () => void): void;
}
export const useRouteChangeCallback: UseRouteChangeCallback = (callback) => {
  const router = i18Next.useRouter();

  useEffect(() => {
    const routerHandler = () => {
      callback();
    };

    router.events.on('routeChangeStart', routerHandler);

    return () => {
      router.events.off('routeChangeStart', routerHandler);
    };
  }, [callback, router.events]);
};
