import { EnteredUserInfoField } from '@/controllers/consultation/consultation.typedefs';
import { SESSION_STORAGE_KEYS } from '@/constants/sessionStorage';
import {
  enteredUserEmailLocalStorage,
  enteredUserPhoneLocalStorage,
} from '@/components/user-survey/userSurvey.utils';

export const useClearLogOutStorageKeys = () => () => {
  // Clear session storage
  sessionStorage.removeItem(
    SESSION_STORAGE_KEYS.enteredUserInfo(EnteredUserInfoField.Name),
  );
  sessionStorage.removeItem(
    SESSION_STORAGE_KEYS.enteredUserInfo(EnteredUserInfoField.Phone),
  );
  sessionStorage.removeItem(
    SESSION_STORAGE_KEYS.enteredUserInfo(EnteredUserInfoField.Email),
  );
  // Clear local storage
  enteredUserEmailLocalStorage.removeFromStorage();
  enteredUserPhoneLocalStorage.removeFromStorage();
};
