import getConfig from 'next/config';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';
import { EnteredUserInfoField } from '@/controllers/consultation/consultation.typedefs';
import { initLocalStorage } from '@/lib/browserStorage';
import { CourseType } from '@/controllers/graphql/generated';
import { AppEnvironments } from '@/lib/constants/general';

export const enteredUserEmailLocalStorage = initLocalStorage({
  key: LOCAL_STORAGE_KEYS.enteredUserInfo(EnteredUserInfoField.Email),
  initialValue: '',
});

export const enteredUserPhoneLocalStorage = initLocalStorage({
  key: LOCAL_STORAGE_KEYS.enteredUserInfo(EnteredUserInfoField.Phone),
  initialValue: '',
});

interface Props {
  email: string;
  phone: string;
}

export const saveUserInfoToLocalStorage = (props: Props) => {
  const {
    email,
    phone,
  } = props;

  enteredUserEmailLocalStorage.writeToStorage(email);
  enteredUserPhoneLocalStorage.writeToStorage(phone);
};

export const getSurveyEnvironmentType = (): AppEnvironments => {
  const { publicRuntimeConfig = {} } = getConfig() || {};
  const { APP_ENV } = publicRuntimeConfig;

  return APP_ENV === AppEnvironments.Production
    ? AppEnvironments.Production
    : AppEnvironments.Development;
};

export const getSurveyCourseFormType = (courseType: CourseType): CourseType => (
  courseType === CourseType.Postpaid
    ? CourseType.Postpaid
    : CourseType.Prepaid);
