import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { colorTokens } from '@/components/ui/colorTokens';

export const IconUser: FCIcon = (props) => (
  <BaseIcon
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM7.013 18.256C8.42855 19.3877 10.1877 20.0029 12 20C13.8923 20.0027 15.7239 19.3321 17.167 18.108C16.5157 17.4397 15.7371 16.9089 14.877 16.5468C14.017 16.1847 13.0931 15.9988 12.16 16C11.1926 15.9989 10.2355 16.1989 9.34947 16.5872C8.46343 16.9756 7.66778 17.5439 7.013 18.256ZM5.616 16.82C6.45645 15.9281 7.47067 15.2177 8.59614 14.7327C9.72161 14.2477 10.9345 13.9984 12.16 14C13.3417 13.9985 14.512 14.2304 15.6037 14.6824C16.6955 15.1344 17.6873 15.7976 18.522 16.634C19.3781 15.4291 19.8836 14.0106 19.9826 12.5359C20.0815 11.0612 19.77 9.58789 19.0825 8.27946C18.395 6.97102 17.3585 5.87862 16.088 5.12345C14.8174 4.36827 13.3625 3.97984 11.8846 4.00125C10.4067 4.02267 8.96366 4.45308 7.71552 5.24476C6.46738 6.03643 5.46296 7.1584 4.81369 8.4862C4.16442 9.814 3.89569 11.2957 4.03732 12.7669C4.17894 14.2382 4.72537 15.6414 5.616 16.821V16.82ZM12 13C10.9391 13 9.92172 12.5786 9.17157 11.8284C8.42143 11.0783 8 10.0609 8 9C8 7.93913 8.42143 6.92172 9.17157 6.17157C9.92172 5.42143 10.9391 5 12 5C13.0609 5 14.0783 5.42143 14.8284 6.17157C15.5786 6.92172 16 7.93913 16 9C16 10.0609 15.5786 11.0783 14.8284 11.8284C14.0783 12.5786 13.0609 13 12 13ZM12 11C12.5304 11 13.0391 10.7893 13.4142 10.4142C13.7893 10.0391 14 9.53043 14 9C14 8.46957 13.7893 7.96086 13.4142 7.58579C13.0391 7.21071 12.5304 7 12 7C11.4696 7 10.9609 7.21071 10.5858 7.58579C10.2107 7.96086 10 8.46957 10 9C10 9.53043 10.2107 10.0391 10.5858 10.4142C10.9609 10.7893 11.4696 11 12 11Z" fill={colorTokens.icon} />
  </BaseIcon>
);
