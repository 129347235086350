import { useTranslation } from '@/middleware/i18n';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';

export const useTranslateByDomain = (code: string) => {
  const { t, i18n } = useTranslation();
  const { subDomain } = useSubDomainContext();

  if (i18n.exists(`${code}|${subDomain}`)) {
    return t(`${code}|${subDomain}`);
  }

  return t(code);
};
