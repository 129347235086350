import { useCallback, useEffect } from 'react';
import { KeyCodes } from '@/controllers/keyPress/keyPress.typedefs';

export const useKeyPress = (
  key: KeyCodes,
  func?: () => any,
  dependencies: any[] = [],
) => {
  const callback = useCallback((e: KeyboardEvent) => {
    if (e.code === key && func) {
      func();
    }
  }, [func, key]);

  useEffect(() => {
    window.addEventListener('keyup', callback);

    return () => {
      window.removeEventListener('keyup', callback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, callback]);
};
